import { globalVar } from "./globals";
export function throwErr(...args) {
    if (typeof args[0] === "string") {
        throw new StackAssertionError(args[0], args[1]);
    }
    else if (args[0] instanceof Error) {
        throw args[0];
    }
    else {
        // @ts-expect-error
        throw new StatusError(...args);
    }
}
function removeStacktraceNameLine(stack) {
    // some browsers (eg. Chrome) prepend the stack with an extra line with the error name
    const addsNameLine = new Error().stack?.startsWith("Error\n");
    return stack.split("\n").slice(addsNameLine ? 1 : 0).join("\n");
}
/**
 * Concatenates the stacktraces of the given errors onto the first.
 *
 * Useful when you invoke an async function to receive a promise without awaiting it immediately. Browsers are smart
 * enough to keep track of the call stack in async function calls when you invoke `.then` within the same async tick,
 * but if you don't,
 *
 * Here's an example of the unwanted behavior:
 *
 * ```tsx
 * async function log() {
 *   await wait(0);  // simulate an put the task on the event loop
 *   console.log(new Error().stack);
 * }
 *
 * async function main() {
 *   await log();  // good; prints both "log" and "main" on the stacktrace
 *   log();  // bad; prints only "log" on the stacktrace
 * }
 * ```
 */
export function concatStacktraces(first, ...errors) {
    // some browsers (eg. Firefox) add an extra empty line at the end
    const addsEmptyLineAtEnd = first.stack?.endsWith("\n");
    // Add a reference to this function itself so that we know that stacktraces were concatenated
    // If you are coming here from a stacktrace, please know that the two parts before and after this line are different
    // stacktraces that were concatenated with concatStacktraces
    const separator = removeStacktraceNameLine(new Error().stack ?? "").split("\n")[0];
    for (const error of errors) {
        const toAppend = removeStacktraceNameLine(error.stack ?? "");
        first.stack += (addsEmptyLineAtEnd ? "" : "\n") + separator + "\n" + toAppend;
    }
}
export class StackAssertionError extends Error {
    constructor(message, extraData, options) {
        const disclaimer = `\n\nThis is likely an error in Stack. Please make sure you are running the newest version and report it.`;
        super(`${message}${message.endsWith(disclaimer) ? "" : disclaimer}`, options);
        this.extraData = extraData;
        this.customCaptureExtraArgs = [
            {
                ...this.extraData,
                ...this.cause ? { cause: this.cause } : {},
            },
        ];
    }
}
StackAssertionError.prototype.name = "StackAssertionError";
const errorSinks = new Set();
export function registerErrorSink(sink) {
    if (errorSinks.has(sink)) {
        return;
    }
    errorSinks.add(sink);
}
registerErrorSink((location, ...args) => {
    console.error(`\x1b[41mCaptured error in ${location}:`, ...args, "\x1b[0m");
});
registerErrorSink((location, error, ...extraArgs) => {
    globalVar.stackCapturedErrors = globalVar.stackCapturedErrors ?? [];
    globalVar.stackCapturedErrors.push({ location, error, extraArgs });
});
export function captureError(location, error) {
    for (const sink of errorSinks) {
        sink(location, error, ...error && (typeof error === 'object' || typeof error === 'function') && "customCaptureExtraArgs" in error && Array.isArray(error.customCaptureExtraArgs) ? error.customCaptureExtraArgs : []);
    }
}
export class StatusError extends Error {
    constructor(status, message) {
        if (typeof status === "object") {
            message ??= status.message;
            status = status.statusCode;
        }
        super(message);
        this.name = "StatusError";
        this.statusCode = status;
        if (!message) {
            throw new StackAssertionError("StatusError always requires a message unless a Status object is passed", {}, { cause: this });
        }
    }
    isClientError() {
        return this.statusCode >= 400 && this.statusCode < 500;
    }
    isServerError() {
        return !this.isClientError();
    }
    getStatusCode() {
        return this.statusCode;
    }
    getBody() {
        return new TextEncoder().encode(this.message);
    }
    getHeaders() {
        return {
            "Content-Type": ["text/plain; charset=utf-8"],
        };
    }
    toDescriptiveJson() {
        return {
            status_code: this.getStatusCode(),
            message: this.message,
            headers: this.getHeaders(),
        };
    }
    /**
     * @deprecated this is not a good way to make status errors human-readable, use toDescriptiveJson instead
     */
    toHttpJson() {
        return {
            status_code: this.statusCode,
            body: this.message,
            headers: this.getHeaders(),
        };
    }
}
StatusError.BadRequest = { statusCode: 400, message: "Bad Request" };
StatusError.Unauthorized = { statusCode: 401, message: "Unauthorized" };
StatusError.PaymentRequired = { statusCode: 402, message: "Payment Required" };
StatusError.Forbidden = { statusCode: 403, message: "Forbidden" };
StatusError.NotFound = { statusCode: 404, message: "Not Found" };
StatusError.MethodNotAllowed = { statusCode: 405, message: "Method Not Allowed" };
StatusError.NotAcceptable = { statusCode: 406, message: "Not Acceptable" };
StatusError.ProxyAuthenticationRequired = { statusCode: 407, message: "Proxy Authentication Required" };
StatusError.RequestTimeout = { statusCode: 408, message: "Request Timeout" };
StatusError.Conflict = { statusCode: 409, message: "Conflict" };
StatusError.Gone = { statusCode: 410, message: "Gone" };
StatusError.LengthRequired = { statusCode: 411, message: "Length Required" };
StatusError.PreconditionFailed = { statusCode: 412, message: "Precondition Failed" };
StatusError.PayloadTooLarge = { statusCode: 413, message: "Payload Too Large" };
StatusError.URITooLong = { statusCode: 414, message: "URI Too Long" };
StatusError.UnsupportedMediaType = { statusCode: 415, message: "Unsupported Media Type" };
StatusError.RangeNotSatisfiable = { statusCode: 416, message: "Range Not Satisfiable" };
StatusError.ExpectationFailed = { statusCode: 417, message: "Expectation Failed" };
StatusError.ImATeapot = { statusCode: 418, message: "I'm a teapot" };
StatusError.MisdirectedRequest = { statusCode: 421, message: "Misdirected Request" };
StatusError.UnprocessableEntity = { statusCode: 422, message: "Unprocessable Entity" };
StatusError.Locked = { statusCode: 423, message: "Locked" };
StatusError.FailedDependency = { statusCode: 424, message: "Failed Dependency" };
StatusError.TooEarly = { statusCode: 425, message: "Too Early" };
StatusError.UpgradeRequired = { statusCode: 426, message: "Upgrade Required" };
StatusError.PreconditionRequired = { statusCode: 428, message: "Precondition Required" };
StatusError.TooManyRequests = { statusCode: 429, message: "Too Many Requests" };
StatusError.RequestHeaderFieldsTooLarge = { statusCode: 431, message: "Request Header Fields Too Large" };
StatusError.UnavailableForLegalReasons = { statusCode: 451, message: "Unavailable For Legal Reasons" };
StatusError.InternalServerError = { statusCode: 500, message: "Internal Server Error" };
StatusError.NotImplemented = { statusCode: 501, message: "Not Implemented" };
StatusError.BadGateway = { statusCode: 502, message: "Bad Gateway" };
StatusError.ServiceUnavailable = { statusCode: 503, message: "Service Unavailable" };
StatusError.GatewayTimeout = { statusCode: 504, message: "Gateway Timeout" };
StatusError.HTTPVersionNotSupported = { statusCode: 505, message: "HTTP Version Not Supported" };
StatusError.VariantAlsoNegotiates = { statusCode: 506, message: "Variant Also Negotiates" };
StatusError.InsufficientStorage = { statusCode: 507, message: "Insufficient Storage" };
StatusError.LoopDetected = { statusCode: 508, message: "Loop Detected" };
StatusError.NotExtended = { statusCode: 510, message: "Not Extended" };
StatusError.NetworkAuthenticationRequired = { statusCode: 511, message: "Network Authentication Required" };
StatusError.prototype.name = "StatusError";
